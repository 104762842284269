/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

	        
			



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
	        $('.chat-start').click(function(){
		        $('.intelecomchatstarter').trigger('click');
	        });

        	var scrollPx = 350;
        	if($(window).width() > 1000){
	        	scrollPx = 600;
        	}
        	
        	var scrolled = 0;
        	
			$(window).scroll(function() {			
			    if ($(this).scrollTop() >= scrollPx && scrolled === 0 && !$('#menu-burger').hasClass('open') && !$('.nav-bar .nav-right').hasClass('open-nav')) { 
			        $('.nav-bar').addClass('scrolled');
			        $('.nav-bar').css('top','-100px');
			        $('.nav-bar').css('opacity','0');
			        $('.nav-bar').animate({
					   opacity: 1,
					   top: '0px'
				   }, 200);			        
			        scrolled = 1;
			    }
			    
				if ($(this).scrollTop() < scrollPx && scrolled === 1 && !$('#menu-burger').hasClass('open') && !$('.nav-bar .nav-right').hasClass('open-nav')) { 
					$('.nav-bar').animate({
					    opacity: 0,
					    top: '-100px',
					  }, 200, function() {
					    $('.nav-bar').css('top','0px');
						$('.nav-bar').css('opacity','1');
						$('.nav-bar').removeClass('scrolled');
						
					  });		   
			        scrolled = 0;
			    }
			});
        
        
        if($('body').hasClass('page-template-template-faq')){
	        
	        $('.item').click(function(){
		        $(this).toggleClass('active');
		        $(this).children('.a').slideToggle();
	        });
	        
        } // If body -> page-template-template-faq
        
        
             $('.webinar-wrapper .item .top').click(function(){
		        $(this).toggleClass('active');
		        $(this).parent('.item').find('.generell-form_wrapper').slideToggle();
	        });
        
        
        
		/* Menu */
		$('#menu-burger').click(function(){
			 $('body').toggleClass('fixed-body');
			$(this).toggleClass('open');
			$('.nav-bar .nav-right').toggleClass('open-nav');
		});
		
        
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        
        
		$('#start-carousel').carousel();		    		
		
		$('#start-carousel').on('slide.bs.carousel', function () {		
		
		});
		
        
        
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
